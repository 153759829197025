import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import { formatFirestoreTimestamp } from '../_helpers/mappers';
import JsonModal from '../StoresPage/JsonModal';
import { publicationStatus } from '../_constants/publicationStatus';
import { mobilePlatforms } from '../_constants/mobilePlatforms';
import FinishPublication from './FinishPublication';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowWrap: 'anywhere',
    padding: theme.spacing(2),
  },
  propertyContainer: {
    '& a': {
      color: 'black',
      textDecoration: 'none'
    }
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 0
  },
  value: {
    display: '-webkit-box',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  iconButton: {
    marginLeft: 5
  },
  icon: {
    fontSize: 16
  },
  image: {
    border: 'solid 1px #ececec',
    height: 'auto',
    width: 70
  },
  buttonsContainer: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  }
}));

export default function PublicationDetails({ onDataUpdate, publication }) {
  const classes = useStyles();

  const [finishPublicationModal, setFinishPublicationModal] = useState(false);
  const [showJsonModal, setShowJsonModal] = useState(false);

  const createdAtFieldName = 'createdAt';
  const updatedAtFieldName = 'updatedAt';

  const assets = _.get(publication, 'assets', {});
  const metadata = _.get(publication, 'metadata', {});
  const firebase = _.get(publication, 'firebase', {});

  const copyToClipboard = (value) =>
    navigator.clipboard.writeText(value)

  function TextProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <p className={classes.label}> {`${label}`}
          <IconButton className={classes.iconButton} onClick={() => copyToClipboard(data[name])}>
            <FileCopyIcon className={classes.icon} />
          </IconButton>
        </p>
        <p className={classes.value}>{data[name] || '-'}</p>
      </div>
    )
  }

  function ImageProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <a href={data[name]} target="_blank">
          <p className={classes.label}> {`${label}`}
            <IconButton className={classes.iconButton}>
              <LaunchIcon className={classes.icon} />
            </IconButton>
          </p>
          {_.isEmpty(data[name]) ? '-' : <img className={classes.image} src={data[name]} />}
        </a>
      </div>
    )
  }

  function DateProperty({ data, label, name }) {
    return (
      <div className={classes.propertyContainer}>
        <p className={classes.label}> {`${label}`}</p>
        <p className={classes.value}>{formatFirestoreTimestamp(data[name], 'DD/MM/YYYY HH:mm') || '-'}</p>
      </div>
    )
  }

  
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <h2>Publicação</h2>
          <TextProperty data={publication} label="ID" name={'id'} />
          <TextProperty data={publication} label="ID do Projeto do Expo" name="expoProjectId" />
          <TextProperty data={publication} label="Slug" name="slug" />
          <TextProperty data={firebase} label="Projeto do Firebase" name="firebaseProjectName" />
          <TextProperty data={firebase} label="ID do Aplicativo Firebase" name="firebaseApplicationId" />
          <DateProperty data={publication} label="Criado em" name={createdAtFieldName} />
          <DateProperty data={publication} label="Última atualização de status" name={updatedAtFieldName} />
        </Grid>

        <Grid item xs={3}>
          <h2>Metadados</h2>
          <TextProperty data={metadata} label="Título do aplicativo" name="title" />
          <TextProperty data={metadata} label="Descrição curta" name="shortDescription" />
          <TextProperty data={metadata} label="Descrição" name="description" />
          <TextProperty data={metadata} label="Url da Política de Privacidade" name="privacyPolicyUrl" />
          <TextProperty data={metadata} label="Palavras chave" name="keywords" />
          <TextProperty data={metadata} label="Vídeo promocional" name="promotionalVideo" />
        </Grid>

        <Grid item xs={3}>
          <TextProperty data={metadata} label="Build version" name="version" />
          <TextProperty data={metadata} label="Marketing version" name="marketingVersion" />
          <TextProperty data={metadata} label="Bundle ID" name="bundleId" />
          {publication.mobilePlatform === 'android' && (
            <>
              <TextProperty data={metadata} label="Nome da conta Google" name="developerAccountName" />
              <TextProperty data={metadata} label="Email da conta Google" name="developerAccountEmail" />
              <TextProperty data={metadata} label="Nome da conta de serviço" name="googlePlayServiceAccountName" />
            </>
          )}

          {publication.mobilePlatform === 'ios' && (
            <>
              <TextProperty data={metadata} label="Team Name" name="teamName" />
              <TextProperty data={metadata} label="Team ID" name="teamId" />
              <TextProperty data={metadata} label="ID do aplicativo Apple" name="ascAppId" />
            </>
          )}
          <TextProperty data={metadata} label="ID do Facebook" name="facebookId" />
          <TextProperty data={metadata} label="Token do Facebook" name="facebookClientToken" />
        </Grid>

        <Grid item xs={3}>
          <h2>Assets</h2>
          <ImageProperty data={assets} label="Ícone" name="iconUrl" />
          <ImageProperty data={assets} label="Ícone de notificação" name="notificationIconUrl" />
          <ImageProperty data={assets} label="Tela de introdução" name="launchScreenUrl" />
          <ImageProperty data={assets} label="Banner da Play Store" name="featureGraphicUrl" />
        </Grid>

        <Grid alignItems="center" className={classes.buttonsContainer} container direction="row" item justify="flex-start" xs={12}>
          {publication.mobilePlatform === mobilePlatforms.ANDROID && (
            <Button 
              disabled={publication.status !== publicationStatus.READY}
              variant="contained" 
              onClick={() => handleBundleDownload()}
            >
              Fazer o download do bundle
            </Button>
          )}
          <Button 
            disabled={publication.status !== publicationStatus.READY}
            variant="contained" 
            onClick={() => setFinishPublicationModal(true)}
          >
            Concluir publicação
          </Button>  
          <Button variant="contained" onClick={() => setShowJsonModal(true)}>Ver JSON</Button>
        </Grid>
      </Grid>

      <JsonModal
        data={publication}
        isOpen={showJsonModal}
        onClose={() => setShowJsonModal(false)}
      />

      <FinishPublication
        data={publication}
        isOpen={finishPublicationModal}
        onClose={() => setFinishPublicationModal(false)}
        onDataUpdate={onDataUpdate}
      />
    </div>
  );
}