import React, { useEffect, useState } from 'react';
import MainLayout from '../_layouts/main';
import { useLocation } from "react-router-dom";
import { withFirebase } from '../_firebase';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { icons, localization } from '../_helpers/materialTableProperties';
import { convertArrayToCsv } from '../_helpers/csvConverter';
import _ from 'lodash';
import { formatFirestoreTimestamp, getMobilePlatformChip, getPublicationStatusChip, getPublishStatusChip, getStoreStatusChip, mapFirestoreTimestampToMoment } from '../_helpers/mappers';
import moment from 'moment';
import { Description } from '@material-ui/icons';
import PublicationDetails from './PublicationDetails';

function PublicationsPage({ firebase }) {
    let { search } = useLocation();

    const query = new URLSearchParams(search);
    const defaultFilter = query.get('filter');

    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(true);

    const createdAtFieldName = 'createdAt';
    const nameFieldName = 'name';
    
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);

        firebase.getPublications()
            .then(publications => {
                setPublications(publications);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const redirectToStoreData = (rowData) => {
        if(rowData.platformId && rowData.storeId){
            return window.open(`/platforms/${rowData.platformId}/stores?filter=${rowData.storeId}`, '_blank')
        }else{
            const errorMessage = 'Não é possível acessar o painel do cliente. Por favor, tente sair do painel de controle da Mobilizei e se logar novamente.';
            alert(errorMessage)
            return
        }
        
    }

    return (
        <MainLayout title="Publicações">
            <MaterialTable
                actions={[
                    rowData => ({
                        icon: () => <Description />,
                        tooltip: 'Ver dados da loja',
                        onClick: () => redirectToStoreData(rowData),
                    }),
                ]}
                columns={[
                    {
                        title: 'ID da publicação',
                        field: 'id',
                        defaultFilter: defaultFilter ? defaultFilter : false,
                        customSort: (a, b) => {
                            return parseInt(a['id']) > parseInt(b['id']) ? 1 : -1
                        }
                    },
                    {
                        title: 'ID da loja',
                        field: 'storeId',
                        defaultFilter: defaultFilter ? defaultFilter : false,
                        customSort: (a, b) => {
                            return parseInt(a['storeId']) > parseInt(b['storeId']) ? 1 : -1
                        }
                    },
                    {
                        title: 'Nome do aplicativo',
                        field: nameFieldName,
                        customSort: (a, b) => a[nameFieldName].toLowerCase().localeCompare(b[nameFieldName].toLowerCase())
                    },
                    {
                        title: 'Plataforma',
                        field: 'mobilePlatform',
                        render: rowData => getMobilePlatformChip(_.get(rowData, 'mobilePlatform', '')),
                    },
                    { title: 'Versão', field: 'metadata[marketingVersion]' },
                    {
                        defaultFilter: ['queued', 'build', 'submit', 'metadata', 'ready', 'errored'],
                        title: 'Status da Publicação',
                        field: 'status',
                        lookup: {
                            queued: 'Na fila',
                            build: 'Em construção',
                            submit: 'Enviando',
                            metadata: 'Enviando metadados',
                            ready: 'Pronto',
                            finished: 'Publicado',
                            errored: 'Erro'
                        },
                        render: rowData => getPublicationStatusChip(_.get(rowData, 'status', '')),
                    },
                    {
                        title: 'Data de criação',
                        field: createdAtFieldName,
                        render: rowData => formatFirestoreTimestamp(rowData[createdAtFieldName], 'DD/MM/YYYY'),
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(a[createdAtFieldName]);
                            let date2 = mapFirestoreTimestampToMoment(b[createdAtFieldName]);

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        },
                        defaultSort: 'desc'
                    },
                ]}
                data={publications}
                detailPanel={rowData => <PublicationDetails onDataUpdate={getData} publication={rowData} />}
                icons={icons}
                isLoading={loading}
                localization={localization}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                        let publicationsArray = [];

                        data.forEach(publication => {
                            publicationsArray.push({
                                id: publication.id,
                                name: publication[nameFieldName],
                                createdAt: formatFirestoreTimestamp(publication[createdAtFieldName], 'DD/MM/YYYY'),
                                email: publication.email
                            })
                        });

                        const header = 'ID;Nome da Loja;Data de Cadastro;Email';
                        convertArrayToCsv(publicationsArray, header);
                    },
                    filtering: true,
                    pageSize: 10
                }}
                title={''}
            />
        </MainLayout>
    );
}

export default withRouter(withFirebase(PublicationsPage));